var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "event-board", staticStyle: { "text-align": "center" } },
        [
          _c("div", { staticClass: "open-key-banner-202405" }, [
            _c("div", { staticClass: "key1 open-key" }, [
              _c("a", [
                _vm.checkState(1) == "success"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/success.png"),
                        alt: "적립후",
                      },
                    })
                  : _vm.checkState(1) == "fail"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/fail.png"),
                        alt: "적립실패",
                      },
                    })
                  : _vm.checkState(1) == "default"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/default.png"),
                        alt: "적립전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.takeKey(1)
                        },
                      },
                    })
                  : _vm.checkState(1) == "open"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/open.png"),
                        alt: "박스오픈전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.moveEventDetail()
                        },
                      },
                    })
                  : _vm.checkState(1) == "completion"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/completion.png"),
                        alt: "박스오픈후",
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "key2 open-key" }, [
              _c("a", [
                _vm.checkState(2) == "success"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/success.png"),
                        alt: "적립후",
                      },
                    })
                  : _vm.checkState(2) == "fail"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/fail.png"),
                        alt: "적립실패",
                      },
                    })
                  : _vm.checkState(2) == "default"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/default.png"),
                        alt: "적립전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.takeKey(1)
                        },
                      },
                    })
                  : _vm.checkState(2) == "open"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/open.png"),
                        alt: "박스오픈전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.moveEventDetail()
                        },
                      },
                    })
                  : _vm.checkState(2) == "completion"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/completion.png"),
                        alt: "박스오픈후",
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "key3 open-key" }, [
              _c("a", [
                _vm.checkState(3) == "success"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/success.png"),
                        alt: "적립후",
                      },
                    })
                  : _vm.checkState(3) == "fail"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/fail.png"),
                        alt: "적립실패",
                      },
                    })
                  : _vm.checkState(3) == "default"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/default.png"),
                        alt: "적립전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.takeKey(1)
                        },
                      },
                    })
                  : _vm.checkState(3) == "open"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/open.png"),
                        alt: "박스오픈전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.moveEventDetail()
                        },
                      },
                    })
                  : _vm.checkState(3) == "completion"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/completion.png"),
                        alt: "박스오픈후",
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "key4 open-key" }, [
              _c("a", [
                _vm.checkState(4) == "success"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/success.png"),
                        alt: "적립후",
                      },
                    })
                  : _vm.checkState(4) == "fail"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/fail.png"),
                        alt: "적립실패",
                      },
                    })
                  : _vm.checkState(4) == "default"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/default.png"),
                        alt: "적립전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.takeKey(2)
                        },
                      },
                    })
                  : _vm.checkState(4) == "open"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/open.png"),
                        alt: "박스오픈전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.moveEventDetail()
                        },
                      },
                    })
                  : _vm.checkState(4) == "completion"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/completion.png"),
                        alt: "박스오픈후",
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "key5 open-key" }, [
              _c("a", [
                _vm.checkState(5) == "success"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/success.png"),
                        alt: "적립후",
                      },
                    })
                  : _vm.checkState(5) == "fail"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/fail.png"),
                        alt: "적립실패",
                      },
                    })
                  : _vm.checkState(5) == "default"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/default.png"),
                        alt: "적립전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.takeKey(2)
                        },
                      },
                    })
                  : _vm.checkState(5) == "open"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/open.png"),
                        alt: "박스오픈전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.moveEventDetail()
                        },
                      },
                    })
                  : _vm.checkState(5) == "completion"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/completion.png"),
                        alt: "박스오픈후",
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "key6 open-key" }, [
              _c("a", [
                _vm.checkState(6) == "success"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/success.png"),
                        alt: "적립후",
                      },
                    })
                  : _vm.checkState(6) == "fail"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/fail.png"),
                        alt: "적립실패",
                      },
                    })
                  : _vm.checkState(6) == "default"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/default.png"),
                        alt: "적립전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.takeKey(2)
                        },
                      },
                    })
                  : _vm.checkState(6) == "open"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/open.png"),
                        alt: "박스오픈전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.moveEventDetail()
                        },
                      },
                    })
                  : _vm.checkState(6) == "completion"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/completion.png"),
                        alt: "박스오픈후",
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "key7 open-key" }, [
              _c("a", [
                _vm.checkState(7) == "success"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/success.png"),
                        alt: "적립후",
                      },
                    })
                  : _vm.checkState(7) == "fail"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/fail.png"),
                        alt: "적립실패",
                      },
                    })
                  : _vm.checkState(7) == "default"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/default.png"),
                        alt: "적립전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.takeKey(3)
                        },
                      },
                    })
                  : _vm.checkState(7) == "open"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/open.png"),
                        alt: "박스오픈전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.moveEventDetail()
                        },
                      },
                    })
                  : _vm.checkState(7) == "completion"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/completion.png"),
                        alt: "박스오픈후",
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "key8 open-key" }, [
              _c("a", [
                _vm.checkState(8) == "success"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/success.png"),
                        alt: "적립후",
                      },
                    })
                  : _vm.checkState(8) == "fail"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/fail.png"),
                        alt: "적립실패",
                      },
                    })
                  : _vm.checkState(8) == "default"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/default.png"),
                        alt: "적립전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.takeKey(3)
                        },
                      },
                    })
                  : _vm.checkState(8) == "open"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/open.png"),
                        alt: "박스오픈전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.moveEventDetail()
                        },
                      },
                    })
                  : _vm.checkState(8) == "completion"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/completion.png"),
                        alt: "박스오픈후",
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "key9 open-key" }, [
              _c("a", [
                _vm.checkState(9) == "success"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/success.png"),
                        alt: "적립후",
                      },
                    })
                  : _vm.checkState(9) == "fail"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/fail.png"),
                        alt: "적립실패",
                      },
                    })
                  : _vm.checkState(9) == "default"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/default.png"),
                        alt: "적립전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.takeKey(3)
                        },
                      },
                    })
                  : _vm.checkState(9) == "open"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240415/open.png"),
                        alt: "박스오픈전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.moveEventDetail()
                        },
                      },
                    })
                  : _vm.checkState(9) == "completion"
                  ? _c("img", {
                      staticStyle: { cursor: "default" },
                      attrs: {
                        src: require("@/assets/images/events/event240415/completion.png"),
                        alt: "박스오픈후",
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "btn-open-detail" }, [
            _c(
              "a",
              {
                attrs: { title: "이벤트 자세히 보러가기" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.$router
                      .push({
                        name: "app-event-main",
                        query: { id: _vm.noticeId, entry: 191 },
                      })
                      .catch(function () {})
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/events/event240415/event-detail.png"),
                    alt: "이벤트 자세히 보러가기",
                  },
                }),
              ]
            ),
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/events/event240415/banner-05.jpg"),
              alt: "열려라 럭키박스",
            },
          }),
        ]
      ),
      _c(_vm.popupComponent, {
        ref: "preProcessPopup",
        tag: "component",
        attrs: { "event-id": _vm.eventId },
        on: { close: _vm.closePreProcessPopup },
      }),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }