<template>
  <div>
    <div
      class="event-board"
      style="text-align: center;"
    >
      <div class="open-key-banner-202405">
        <div class="key1 open-key">
          <a>
            <img
              v-if="checkState(1) == 'success'"
              src="@/assets/images/events/event240415/success.png"
              alt="적립후"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(1) == 'fail'"
              src="@/assets/images/events/event240415/fail.png"
              alt="적립실패"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(1) == 'default'"
              src="@/assets/images/events/event240415/default.png"
              alt="적립전"
              @click.stop="takeKey(1)"
            >
            <img
              v-else-if="checkState(1) == 'open'"
              src="@/assets/images/events/event240415/open.png"
              alt="박스오픈전"
              @click.stop="moveEventDetail()"
            >
            <img
              v-else-if="checkState(1) == 'completion'"
              src="@/assets/images/events/event240415/completion.png"
              alt="박스오픈후"
              style="cursor: default;"
            >
          </a>
        </div>
        <div class="key2 open-key">
          <a>
            <img
              v-if="checkState(2) == 'success'"
              src="@/assets/images/events/event240415/success.png"
              alt="적립후"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(2) == 'fail'"
              src="@/assets/images/events/event240415/fail.png"
              alt="적립실패"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(2) == 'default'"
              src="@/assets/images/events/event240415/default.png"
              alt="적립전"
              @click.stop="takeKey(1)"
            >
            <img
              v-else-if="checkState(2) == 'open'"
              src="@/assets/images/events/event240415/open.png"
              alt="박스오픈전"
              @click.stop="moveEventDetail()"
            >
            <img
              v-else-if="checkState(2) == 'completion'"
              src="@/assets/images/events/event240415/completion.png"
              alt="박스오픈후"
              style="cursor: default;"
            >
          </a></div>
        <div class="key3 open-key">
          <a>
            <img
              v-if="checkState(3) == 'success'"
              src="@/assets/images/events/event240415/success.png"
              alt="적립후"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(3) == 'fail'"
              src="@/assets/images/events/event240415/fail.png"
              alt="적립실패"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(3) == 'default'"
              src="@/assets/images/events/event240415/default.png"
              alt="적립전"
              @click.stop="takeKey(1)"
            >
            <img
              v-else-if="checkState(3) == 'open'"
              src="@/assets/images/events/event240415/open.png"
              alt="박스오픈전"
              @click.stop="moveEventDetail()"
            >
            <img
              v-else-if="checkState(3) == 'completion'"
              src="@/assets/images/events/event240415/completion.png"
              alt="박스오픈후"
              style="cursor: default;"
            >
          </a>
        </div>
        <div class="key4 open-key">
          <a>
            <img
              v-if="checkState(4) == 'success'"
              src="@/assets/images/events/event240415/success.png"
              alt="적립후"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(4) == 'fail'"
              src="@/assets/images/events/event240415/fail.png"
              alt="적립실패"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(4) == 'default'"
              src="@/assets/images/events/event240415/default.png"
              alt="적립전"
              @click.stop="takeKey(2)"
            >
            <img
              v-else-if="checkState(4) == 'open'"
              src="@/assets/images/events/event240415/open.png"
              alt="박스오픈전"
              @click.stop="moveEventDetail()"
            >
            <img
              v-else-if="checkState(4) == 'completion'"
              src="@/assets/images/events/event240415/completion.png"
              alt="박스오픈후"
              style="cursor: default;"
            >
          </a>
        </div>
        <div class="key5 open-key">
          <a>
            <img
              v-if="checkState(5) == 'success'"
              src="@/assets/images/events/event240415/success.png"
              alt="적립후"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(5) == 'fail'"
              src="@/assets/images/events/event240415/fail.png"
              alt="적립실패"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(5) == 'default'"
              src="@/assets/images/events/event240415/default.png"
              alt="적립전"
              @click.stop="takeKey(2)"
            >
            <img
              v-else-if="checkState(5) == 'open'"
              src="@/assets/images/events/event240415/open.png"
              alt="박스오픈전"
              @click.stop="moveEventDetail()"
            >
            <img
              v-else-if="checkState(5) == 'completion'"
              src="@/assets/images/events/event240415/completion.png"
              alt="박스오픈후"
              style="cursor: default;"
            >
          </a>
        </div>
        <div class="key6 open-key">
          <a>
            <img
              v-if="checkState(6) == 'success'"
              src="@/assets/images/events/event240415/success.png"
              alt="적립후"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(6) == 'fail'"
              src="@/assets/images/events/event240415/fail.png"
              alt="적립실패"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(6) == 'default'"
              src="@/assets/images/events/event240415/default.png"
              alt="적립전"
              @click.stop="takeKey(2)"
            >
            <img
              v-else-if="checkState(6) == 'open'"
              src="@/assets/images/events/event240415/open.png"
              alt="박스오픈전"
              @click.stop="moveEventDetail()"
            >
            <img
              v-else-if="checkState(6) == 'completion'"
              src="@/assets/images/events/event240415/completion.png"
              alt="박스오픈후"
              style="cursor: default;"
            >
          </a>
        </div>
        <div class="key7 open-key">
          <a>
            <img
              v-if="checkState(7) == 'success'"
              src="@/assets/images/events/event240415/success.png"
              alt="적립후"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(7) == 'fail'"
              src="@/assets/images/events/event240415/fail.png"
              alt="적립실패"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(7) == 'default'"
              src="@/assets/images/events/event240415/default.png"
              alt="적립전"
              @click.stop="takeKey(3)"
            >
            <img
              v-else-if="checkState(7) == 'open'"
              src="@/assets/images/events/event240415/open.png"
              alt="박스오픈전"
              @click.stop="moveEventDetail()"
            >
            <img
              v-else-if="checkState(7) == 'completion'"
              src="@/assets/images/events/event240415/completion.png"
              alt="박스오픈후"
              style="cursor: default;"
            >
          </a>
        </div>
        <div class="key8 open-key">
          <a>
            <img
              v-if="checkState(8) == 'success'"
              src="@/assets/images/events/event240415/success.png"
              alt="적립후"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(8) == 'fail'"
              src="@/assets/images/events/event240415/fail.png"
              alt="적립실패"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(8) == 'default'"
              src="@/assets/images/events/event240415/default.png"
              alt="적립전"
              @click.stop="takeKey(3)"
            >
            <img
              v-else-if="checkState(8) == 'open'"
              src="@/assets/images/events/event240415/open.png"
              alt="박스오픈전"
              @click.stop="moveEventDetail()"
            >
            <img
              v-else-if="checkState(8) == 'completion'"
              src="@/assets/images/events/event240415/completion.png"
              alt="박스오픈후"
              style="cursor: default;"
            >
          </a>
        </div>
        <div class="key9 open-key">
          <a>
            <img
              v-if="checkState(9) == 'success'"
              src="@/assets/images/events/event240415/success.png"
              alt="적립후"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(9) == 'fail'"
              src="@/assets/images/events/event240415/fail.png"
              alt="적립실패"
              style="cursor: default;"
            >
            <img
              v-else-if="checkState(9) == 'default'"
              src="@/assets/images/events/event240415/default.png"
              alt="적립전"
              @click.stop="takeKey(3)"
            >
            <img
              v-else-if="checkState(9) == 'open'"
              src="@/assets/images/events/event240415/open.png"
              alt="박스오픈전"
              @click.stop="moveEventDetail()"
            >
            <img
              v-else-if="checkState(9) == 'completion'"
              src="@/assets/images/events/event240415/completion.png"
              alt="박스오픈후"
              style="cursor: default;"
            >
          </a>
        </div>
      </div>

      <!-- 이벤트 자세히 보러가기 -->
      <div class="btn-open-detail">
        <a
          title="이벤트 자세히 보러가기"
          @click.stop="$router.push({ name: 'app-event-main', query: { id: noticeId, entry: 191 } }).catch(() => {})"
        ><img
          src="@/assets/images/events/event240415/event-detail.png"
          alt="이벤트 자세히 보러가기"
        ></a>
      </div>
      <!-- 배경 -->
      <img
        src="@/assets/images/events/event240415/banner-05.jpg"
        alt="열려라 럭키박스"
      >
    </div>

    <component
      :is="popupComponent"
      ref="preProcessPopup"
      :event-id="eventId"
      @close="closePreProcessPopup"
    />
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>
<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { isUserLoggedIn, } from '@/auth/utils'

import { setStatistics } from '@/common/statistics/service'

require('@/assets/scss/event.scss')

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    noticeId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      today: dayjs().format('YYYY-MM-DD'),
      completeEvent: false,
      takeKeyCountTotal: 0,
      openBoxCountTotal: 0,
      isProcessing: false,
      isLoggedInUser: false,
      selectedStep: 0,
      startDateStep1: '2024-05-01',
      startDateStep2: '2024-05-11',
      startDateStep3: '2024-05-23',
      endDateStep1: '2024-05-10',
      endDateStep2: '2024-05-22',
      endDateStep3: '2024-05-31',
      takeKeyCountStep1: 0,
      takeKeyCountStep2: 0,
      takeKeyCountStep3: 0,
    }
  },
  computed: {
    ...mapGetters({
      join: 'util/getJoin', // 회원여부
      userName: 'account/getName', // 회원 로그인 여부
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    }),
    popupComponent() {
      return () => import('@/components/popups/EventJoinAgreeForm.vue')
    },
  },
  watch: {
    shaEnLicenseNum() {
      if (this.shaEnLicenseNum) {
        this.fetchEventHistory()
        this.fetchEventComplete()
      }
    },
    userName() {
      if (this.userName) {
        this.fetchEventHistory()
        this.fetchEventComplete()
      } else {
        this.isLoggedInUser = false
      }
    },
  },
  async created() {
    this.isLoggedInUser = await isUserLoggedIn()

    await this.fetchEventHistory()
    await this.fetchEventComplete()
  },
  methods: {
    checkDisableDate(stDate, edDate) {
      // 기간 외인 경우 disable (fail)
      // return (this.today < stDate || edDate < this.today)
      // 기간 후인 경우 disable (fail)
      return (edDate < this.today)
    },
    checkDateEnd(toDate) {
      return toDate < this.today
    },
    checkState(position) {
      // position : 위치 1~9
      // state : 상태 [ default_적립전 / fail_적립실패 / success_적립후 / open_박스오픈전 / completion_박스오픈후 ]
      let viewState = 'default'

      // 회원이고 로그인하지 않은 경우 디폴트 리턴
      if (this.join && !this.isLoggedInUser) { return viewState }

      if (position < 4) {
        // 구간 시작일
        const startDateStepNow = this.startDateStep1
        // 구간 종료일
        const endDateStepNow = this.endDateStep1
        // 현재 구간의 위치 (5인 경우 2구간의 2이므로, 2)
        const cPosition = position
        // 이전 구간에서 얻은 열쇠 수 (이전 구간의 Key Count)
        const pStepKey = 0
        // 현재 구간에서 얻은 열쇠 수
        const cStepKey = this.takeKeyCountStep1

        // 현재 구간에서 포지션까지 얻은 열쇠 수 (step2에서 3개를 모았고 2포지션인 경우, 2)
        const cStepKeyPosition = Math.min(cStepKey, cPosition)
        // 전체 구간에서 포지션까지 얻은 열쇠 수 => 상자여부 판별에 사용
        const tStepKeyPosition = pStepKey + cStepKeyPosition

        if (cStepKey < cPosition) {
          // 적립여부 확인
          if (this.checkDisableDate(startDateStepNow, endDateStepNow)) {
            // 이벤트 기간 확인
            viewState = 'fail'
          } else {
            viewState = 'default'
          }
        } else if (tStepKeyPosition % 3 === 0 && tStepKeyPosition > 0) {
          // 박스 여부 확인
          if (this.openBoxCountTotal * 3 < tStepKeyPosition) {
            viewState = 'open'
          } else {
            viewState = 'completion'
          }
        } else {
          viewState = 'success'
        }
      } else if (position < 7) {
        // 구간 시작일
        const startDateStepNow = this.startDateStep2
        // 구간 종료일
        const endDateStepNow = this.endDateStep2
        // 현재 구간의 위치 (5인 경우 2구간의 2이므로, 2)
        const cPosition = position - 3
        // 이전 구간에서 얻은 열쇠 수 (이전 구간의 Key Count)
        const pStepKey = this.takeKeyCountStep1
        // 현재 구간에서 얻은 열쇠 수
        const cStepKey = this.takeKeyCountStep2

        // 현재 구간에서 포지션까지 얻은 열쇠 수 (step2에서 3개를 모았고 2포지션인 경우, 2)
        const cStepKeyPosition = Math.min(cStepKey, cPosition)
        // 전체 구간에서 포지션까지 얻은 열쇠 수 => 상자여부 판별에 사용
        const tStepKeyPosition = pStepKey + cStepKeyPosition

        if (cStepKey < cPosition) {
          // 적립여부 확인
          if (this.checkDisableDate(startDateStepNow, endDateStepNow)) {
            // 이벤트 기간 확인
            viewState = 'fail'
          } else {
            viewState = 'default'
          }
        } else if (tStepKeyPosition % 3 === 0 && tStepKeyPosition > 0) {
          // 박스 여부 확인
          if (this.openBoxCountTotal * 3 < tStepKeyPosition) {
            viewState = 'open'
          } else {
            viewState = 'completion'
          }
        } else {
          viewState = 'success'
        }
      } else if (position < 10) {
        // 구간 시작일
        const startDateStepNow = this.startDateStep3
        // 구간 종료일
        const endDateStepNow = this.endDateStep3
        // 현재 구간의 위치 (5인 경우 2구간의 2이므로, 2)
        const cPosition = position - 6
        // 이전 구간에서 얻은 열쇠 수 (이전 구간의 Key Count)
        const pStepKey = this.takeKeyCountStep1 + this.takeKeyCountStep2
        // 현재 구간에서 얻은 열쇠 수
        const cStepKey = this.takeKeyCountStep3

        // 현재 구간에서 포지션까지 얻은 열쇠 수 (step2에서 3개를 모았고 2포지션인 경우, 2)
        const cStepKeyPosition = Math.min(cStepKey, cPosition)
        // 전체 구간에서 포지션까지 얻은 열쇠 수 => 상자여부 판별에 사용
        const tStepKeyPosition = pStepKey + cStepKeyPosition

        if (cStepKey < cPosition) {
          // 적립여부 확인
          if (this.checkDisableDate(startDateStepNow, endDateStepNow)) {
            // 이벤트 기간 확인
            viewState = 'fail'
          } else {
            viewState = 'default'
          }
        } else if (tStepKeyPosition % 3 === 0 && tStepKeyPosition > 0) {
          // 박스 여부 확인
          if (this.openBoxCountTotal * 3 < tStepKeyPosition) {
            viewState = 'open'
          } else {
            viewState = 'completion'
          }
        } else {
          viewState = 'success'
        }
      }
      return viewState
    },
    async fetchEventHistory() {
      if (!this.shaEnLicenseNum) {
        // this.showAlertDialog('사용자 정보를 확인할 수 없습니다.\n관리자에게 문의해주세요.')
        return
      }
      const key = await axios.get(`/fu/event/hiscount/${this.eventId}`, {
        params: {
          userHash: this.shaEnLicenseNum,
          isRewardFin: false
        },
      })
        .then(rs => rs.data).catch((err) => {
          this.showAlertDialog(`이벤트 참여 여부 체크 도중 오류가 발생하였습니다.\n잠시 후에 다시 시도하여 주시기 바랍니다.\n${err}`)
          return null
        })

      const box = await axios.get(`/fu/event/hiscount/${this.eventId}`, {
        params: {
          userHash: this.shaEnLicenseNum,
          isRewardFin: true
        },
      })
        .then(rs => rs.data).catch((err) => {
          this.showAlertDialog(`이벤트 보상 여부 체크 도중 오류가 발생하였습니다.\n잠시 후에 다시 시도하여 주시기 바랍니다.\n${err}`)
          return null
        })

      this.takeKeyCountTotal = key
      this.openBoxCountTotal = box

      if (this.today >= this.startDateStep1) {
        const stepOne = await this.getEventHistoryDate(this.startDateStep1, this.endDateStep1)
        this.takeKeyCountStep1 = stepOne
      }
      if (this.today >= this.startDateStep2) {
        const stepTwo = await this.getEventHistoryDate(this.startDateStep2, this.endDateStep2)
        this.takeKeyCountStep2 = stepTwo
      }
      if (this.today >= this.startDateStep3) {
        const stepThree = await this.getEventHistoryDate(this.startDateStep3, this.endDateStep3)
        this.takeKeyCountStep3 = stepThree
      }
    },
    async getEventHistoryDate(stDate, edDate) {
      const data = await axios.get(`/fu/event/hiscount/${this.eventId}/${stDate}/${edDate}`, {
        params: {
          userHash: this.shaEnLicenseNum,
          isRewardFin: false
        },
      })
        .then(rs => rs.data).catch(() => {})

      return data
    },
    async fetchEventComplete() {
      if (!this.shaEnLicenseNum) {
        // this.showAlertDialog('사용자 정보를 확인할 수 없습니다.\n관리자에게 문의해주세요.')
        return
      }

      const isComplete = await axios.get(`/fu/event/complete/${this.eventId}/${this.contentId}`, {
        params: {
          userHash: this.shaEnLicenseNum,
          isRewardFin: false
        },
      })
        .then(rs => rs.data)
        .catch(() => {
        })

      if (isComplete) {
        this.completeEvent = true
      } else this.completeEvent = false
    },
    moveEventDetail() {
      this.showConfirmDialog('럭키 박스를 오픈할 수 있습니다. 이벤트 페이지로 이동하시겠습니까?', (result) => {
        if (result) {
          this.$router.push({ name: 'app-event-main', query: { id: this.noticeId, entry: 191 } }).catch(() => {})
        }
      })
    },
    async takeKey(step) {
      if (!this.shaEnLicenseNum) {
        // this.showAlertDialog('사용자 정보를 확인할 수 없습니다.\n관리자에게 문의해주세요.')
        return
      }

      this.selectedStep = step
      if (this.join) {
        const isLogged = await isUserLoggedIn()
        /// / 회원
        if (isLogged) {
          /// / 로그인 상태
          this.checkEventDate()
        } else {
          /// / 로그아웃 상태
          this.showConfirmDialog('이벤트 참여 및 내역 조회를 위해서는 로그인이 필요합니다.\n지금 로그인하시겠습니까?', (result) => {
            if (result) {
              this.redirectLogin()
            }
          })
        }
      } else {
        /// / 비회원
        this.checkEventDate()
      }
    },
    async checkEventAgree() {
      if (parseInt(this.takeKeyCountTotal, 10) === 0 && !this.join) {
        // 동의 내역 X : 이벤트 참여 동의 팝업 (confirm)
        this.$refs.preProcessPopup.call()
      } else {
        // 동의 내역 O : 열쇠 적립
        this.createHistoryTakeKey()
      }
    },
    checkEventDate() {
      if (this.completeEvent) {
        this.showAlertDialog('이미 참여한 컨텐츠 입니다.')
        return
      }

      if (this.selectedStep === 1) {
        if (this.today < this.startDateStep1) {
          this.showAlertDialog('5월 1일부터 참여 가능합니다.')
          return
        }
      }
      if (this.selectedStep === 2) {
        if (this.today < this.startDateStep2) {
          this.showAlertDialog('5월 11일부터 참여 가능합니다.')
          return
        }
      }
      if (this.selectedStep === 3) {
        if (this.today < this.startDateStep3) {
          this.showAlertDialog('5월 23일부터 참여 가능합니다.')
          return
        }
      }

      this.checkEventAgree()
    },
    async createHistoryTakeKey() {
      if (this.isProcessing) return
      this.isProcessing = true

      const isLogged = await isUserLoggedIn()

      axios.post(`/fu/event/history`, {
        EventId: this.eventId,
        ContentId: this.contentId,
        UserHash: this.shaEnLicenseNum,
        UserName: (isLogged) ? this.userName : this.ysrUserName,
        MedicalDept: this.medicalDept,
        UserWorkAddress: this.hAddress
      })
        .then(async () => {
          setStatistics(190, this.eventId)
          this.completeEvent = true

          await this.fetchEventHistory()

          if (parseInt(this.takeKeyCountTotal % 3, 10) === 0 && this.takeKeyCountTotal > 0) {
            this.isProcessing = false
            this.moveEventDetail()
          } else {
            this.showAlertDialog(`행운 열쇠를 획득하였습니다.\n행운 열쇠 3개를 모아서 럭키박스 1개를 획득해 보세요.`)
            this.isProcessing = false
          }
        }).catch(error => {
          if (error.response && error.response.data) {
            this.showAlertDialog(error.response.data)
          } else {
            this.showAlertDialog('오류가 발생했습니다.\n관리자에게 문의해주세요.')
          }
          this.isProcessing = false
        })
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-medical-trend-main' }, params: { id: this.contentId } }).catch(() => {})
    },
    closePreProcessPopup(isConfirm) {
      if (isConfirm) {
        // 열쇠 적립
        this.createHistoryTakeKey()
      }
    },
  }
}
</script>

<style scoped>
  body {
      margin: 0;
      padding: 0;
  }
  img {
      display: block;
      margin: 0 auto;
  }
  area:hover {
      cursor: pointer;
  }
  .event-board {
      width: 1000px;
      margin: 0 auto;
      position: relative;
  }
  .event-checked img {
      display: none;
      position: absolute;
  }
</style>
